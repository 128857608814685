<template>
  <div class="title-section">
    <div class="columns is-multiline">
      <div
        class="column is-6"
        :class="{ 'is-6': to && action && slotData, 'is-12': !to && !action && !slotData}"
      >
        <h1 class="title has-text-left">{{title}}</h1>
      </div>

      <div class="column is-6" v-if="to">
        <router-link :to="to">
          <button class="button is-intelligential is-pulled-right">
            {{returnText}}
          </button>
        </router-link>
      </div>

      <div class="column is-6" v-if="action">
        <button class="button is-intelligential is-pulled-right" @click="action">
          Agregar
        </button>
      </div>
      <div  class="column is-6" v-if="slotData">
        <slot name="content-actions">
        </slot>
      </div>
      <div class="column line is-12 is-hidden-touch">
        <hr/>
      </div>
      <div class="column line is-12 menu-return" v-if="returnText && returnTo">
        <router-link :to="{ name: returnTo }">
          <i class="fas fa-angle-left"></i> {{returnText}}
        </router-link>
      </div>
      <div class="column line is-12 menu-return" v-if="returnText && returnToFunction">
        <label @click="returnToFunction">
          <i class="fas fa-angle-left"></i> {{returnText}}
        </label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TitleBar',
  props: {
    to: {
      type: Object
    },
    title: {
      type: String,
      required: true
    },
    action: {
      type: Function
    },
    slotData: {
      type: Boolean
    },
    returnText: {
      type: String
    },
    returnTo: {
      type: String
    },
    returnToFunction: {
      type: Function
    }
  }
}
</script>

<style lang="scss" scoped>
  hr {
    margin: 0;
    background-color:#eaeaea !important;
  }
  .column.line {
    padding-top: 0;
    padding-bottom: 0;
  }
  .menu-return {
    margin-top: 5px;
  }
  .menu-return a, .menu-return label {
    font-size: 12px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all .15s ease-out;
    color: #0091ae;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: #007a8c;
      text-decoration: underline;
    }
  }
  .title-section {
    padding-top: 10px;
    .title {
      font-size: 1.6rem;
    }
  }
</style>
